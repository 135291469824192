import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { CalendarDate } from '../common/types';
import { classNames } from '../common/utils/utils';

const _generateCalendar = (currentDate: Date, selectedDates: string[]): CalendarDate[] => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0).getDate();

  const calendarDates: CalendarDate[] = [];

  // Fill dates of the previous month
  for (let i = firstDay.getDay(); i > 0; i--) {
    const date = new Date(year, month, -i + 1);
    calendarDates.push({ date: date.toISOString().split('T')[0] });
  }

  // Fill dates of the current month
  for (let i = 1; i <= lastDay; i++) {
    const date = new Date(year, month, i);
    calendarDates.push({
      date: date.toISOString().split('T')[0],
      isCurrentMonth: true,
      isToday: date.toISOString().split('T')[0] === currentDate.toISOString().split('T')[0],
      isSelected: selectedDates.includes(
        date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
      )
    });
  }

  // Fill dates of the next month
  for (let i = 1; i <= 42 - lastDay - firstDay.getDay(); i++) {
    const date = new Date(year, month + 1, i);
    calendarDates.push({ date: date.toISOString().split('T')[0] });
  }

  return calendarDates;
};

const daysOfWeek = ['Su', 'M', 'T', 'W', 'T', 'F', 'Sa'];

export default function MeetingCalendar({ selectedDates }: { selectedDates: string[] }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const days = _generateCalendar(currentDate, selectedDates);

  // Flip to the previous month
  const handlePrevMonthClick = () => {
    const updatedDate = new Date(currentDate);
    updatedDate.setMonth(updatedDate.getMonth() - 1);
    setCurrentDate(updatedDate);
  };

  // Flip to the next month
  const handleNextMonthClick = () => {
    const updatedDate = new Date(currentDate);
    updatedDate.setMonth(updatedDate.getMonth() + 1);
    setCurrentDate(updatedDate);
  };

  return (
    <div>
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handlePrevMonthClick}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold text-gray-800">
          {currentMonth} {currentYear}
        </div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handleNextMonthClick}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        {daysOfWeek.map((day, dayIdx) => (
          <div key={dayIdx}>{day}</div>
        ))}
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            className={classNames(
              'py-1.5 hover:bg-gray-100 focus:z-10',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.isSelected || day.isToday) && 'font-semibold',
              day.isSelected && 'text-white',
              !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-800',
              !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
              day.isToday && !day.isSelected && 'text-indigo-600',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg'
            )}
          >
            <time
              dateTime={day.date}
              className={classNames(
                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                day.isSelected && day.isToday && 'bg-indigo-600',
                day.isSelected && !day.isToday && 'bg-indigo-100 text-indigo-800'
              )}
            >
              {day.date.split('-').pop()?.replace(/^0/, '') ?? ''}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
}
