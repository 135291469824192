import { useMutation } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { IoCopy, IoSend } from 'react-icons/io5';
import { useLocation, useParams } from 'react-router-dom';
import loadingAnimation from '../animations/loading-animation.json';
import { sendContextualMessage, sendMessage } from '../clients/chatClient';
import MarkdownRenderer from '../components/MarkdownRender';
import Sidebar from '../components/Sidebar';
import logo from '../images/logo_transparent.png';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

export default function ChatPage() {
  // Load params
  const params = useParams();
  const meetingId = Number(params?.meetingId) || -1;
  const [copiedMessage, setCopiedMessage] = useState<string | null>(null);

  const [message, setMessage] = useState<Message>({ role: 'user', content: '' });
  const [chatHistory, setChatHistory] = useState<Message[]>([
    {
      role: 'assistant',
      content:
        meetingId === -1
          ? 'Notewell AI is here to assist you! I am a HIPAA-compliant chat. Let me know how I can help you.'
          : 'I have the context from your meeting notes. What would you like to do next? I can turn your notes into referral letters, case plans, and more!'
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const previousMessageQueueLength = 8;
  // Extract the type of action we are taking sent from the meeting notes page
  const location = useLocation();
  const isGenericAction = location.state?.isGenericAction || false;

  // Create a ref for the bottom of the messages container
  const bottomRef = useRef<HTMLDivElement>(null);

  // Ref for the textarea
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Maximum height in pixels (9 lines x approx 24px per line)
  const MAX_HEIGHT = 9 * 24;

  // Max number of words per line for the user input
  const MAX_WORDS_PER_LINE = 15;

  // Whenever chatHistory or isLoading changes, scroll to the bottom
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory, isLoading]);

  const sendMessageMutation = useMutation({
    mutationFn: (msg: Message) => {
      if (meetingId !== -1) {
        // Send contextual message if conversation id is present
        return sendContextualMessage(
          msg,
          chatHistory.slice(-previousMessageQueueLength),
          meetingId
        );
      }
      return sendMessage(msg, chatHistory.slice(-previousMessageQueueLength));
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      const newMessage: Message = {
        role: data.role,
        content: data.content
      };
      setChatHistory((prev) => [...prev, newMessage]);
      setIsLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setIsLoading(false);
    }
  });

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedMessage(text);
  };

  const renderMessages = () => {
    return chatHistory.map((msg: Message, index) => (
      <div
        key={index}
        className={`p-2 rounded mb-2 flex items-start ${
          msg.role === 'user' ? 'justify-end' : 'justify-start'
        }`}
      >
        {msg.role === 'assistant' && (
          <div className="mr-4 rounded-full bg-white border border-indigo-600 border-opacity-25 shrink-0 p-1">
            <img src={logo} alt="Assistant Logo" className="h-8 w-auto" />
          </div>
        )}

        <div className="mt-1 flex flex-col group">
          {msg.role === 'assistant' ? (
            <>
              <MarkdownRenderer content={msg.content} />
              <div className="mt-2 w-fit">
                <button
                  onClick={() => handleCopy(msg.content)}
                  onMouseEnter={() => setCopiedMessage(null)}
                  className="inline-flex items-center gap-1 text-sm border border-gray-300 rounded px-2 py-1 text-gray-500 bg-white hover:bg-gray-100 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
                >
                  {copiedMessage === msg.content ? (
                    'Copied!'
                  ) : (
                    <>
                      <IoCopy className="h-4 w-4" />
                      Copy
                    </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <span className="whitespace-pre-wrap inline-block bg-gray-100 rounded-lg px-4 py-2">
              {formatContent(msg.content)}
            </span>
          )}
        </div>
      </div>
    ));
  };

  const sendUserMessage = () => {
    const messageText: string = message.content;
    if (messageText.trim() === '') return;

    setChatHistory((prev) => [...prev, message]);
    sendMessageMutation.mutate(message);
    setMessage({ role: 'user', content: '' });
    // Reset the textarea height after sending
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendUserMessage();
  };

  // Handle textarea auto-resizing
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setMessage({ role: 'user', content: newContent });
    if (textareaRef.current) {
      // Reset height to recalc scrollHeight correctly
      textareaRef.current.style.height = 'auto';
      // Set the new height (up to MAX_HEIGHT)
      // Reset height to auto to correctly read scrollHeight
      textareaRef.current.style.height = 'auto';
      const newHeight = Math.min(textareaRef.current.scrollHeight, MAX_HEIGHT);
      textareaRef.current.style.height = `${newHeight}px`;
      // Hide scrollbar unless content exceeds MAX_HEIGHT
      textareaRef.current.style.overflowY =
        textareaRef.current.scrollHeight > MAX_HEIGHT ? 'auto' : 'hidden';
    }
  };

  const formatContent = (content: string): string => {
    const words = content.split(/\s+/);
    let formatted = '';
    for (let i = 0; i < words.length; i++) {
      formatted += words[i] + ' ';
      if ((i + 1) % MAX_WORDS_PER_LINE === 0) {
        formatted += '\n';
      }
    }
    return formatted.trim();
  };

  return (
    <>
      <Sidebar currentPageName={'Notewell AI'} />
      <main className="lg:ml-72 h-screen flex flex-col">
        {/* Scrollable messages container */}
        <div className="flex-grow overflow-y-auto bg-white p-6 mt-6">
          <div className="max-w-3xl mx-auto">
            {renderMessages()}
            {isLoading && (
              <div className="flex items-center mb-2">
                {/* Logo container matching the assistant message layout */}
                <div className="mr-4 ml-2 rounded-full bg-white border border-indigo-600 border-opacity-25 shrink-0 p-1">
                  <img src={logo} alt="Assistant Logo" className="h-8 w-auto" />
                </div>
                {/* Loading animation container aligned with items-center */}
                <div className="flex items-center">
                  <Lottie
                    animationData={loadingAnimation}
                    loop
                    autoPlay
                    style={{ width: '80px', height: '60px', marginTop: '-4px' }}
                  />
                </div>
              </div>
            )}
            {/* Dummy element to anchor the bottom for scrolling */}
            <div ref={bottomRef} />
          </div>
        </div>

        {/* Fixed input container */}
        <form onSubmit={handleSubmit} className="bg-white p-4 mb-8">
          <div className="max-w-3xl mx-auto">
            {/* Pill-shaped input container */}
            <div
              onClick={() => textareaRef.current?.focus()}
              className="flex flex-col bg-gray-50 rounded-lg shadow-sm px-4 py-2 cursor-text border border-transparent focus-within:border-indigo-600/30"
            >
              <textarea
                ref={textareaRef}
                value={message.content}
                onChange={handleTextareaChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    sendUserMessage();
                  }
                }}
                placeholder="Type your message..."
                rows={1}
                style={{ resize: 'none', overflowY: 'auto' }}
                className="flex-grow bg-transparent focus:outline-none text-gray-800"
                disabled={isLoading}
              />
              <button
                type="submit"
                className="mt-2 self-end text-indigo-500 hover:text-indigo-600 disabled:text-gray-400"
                disabled={isLoading}
              >
                <IoSend size={20} />
              </button>
            </div>
          </div>
        </form>
      </main>
    </>
  );
}
