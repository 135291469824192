import { HiOutlineDevicePhoneMobile } from 'react-icons/hi2';

const QRCodeCard = () => {
  return (
    <div className="bg-white rounded-lg p-4 text-center border border-gray-300/50">
      <div className="flex justify-center mb-2">
        <HiOutlineDevicePhoneMobile className="h-8 w-auto text-indigo-600" />
      </div>
      <h2 className="text-sm font-semibold text-gray-800">Record on the Notewell iOS app</h2>
      <p className="text-xs text-gray-600 mb-2">Scan now to install</p>
      <div className="flex justify-center">
        <img src={require('../images/notewell_qr_code.png')} alt="QR Code" className="rounded-md" />
      </div>
    </div>
  );
};

export default QRCodeCard;
