import {
  TemplateBlock as TemplateBlockType,
  TemplateElements,
  TemplateElementsWithLength,
  TemplateElementsWithOptions
} from '../common/types';

/**
 * Get the text for the button based on the state of the mutation and whether
 * the template is being edited or created
 */
export const getButtonText = (showLoadingIcon: boolean, hasChanges: boolean) => {
  if (showLoadingIcon) return 'Saving...';
  if (!hasChanges) return 'Changes Saved';
  else return 'Save Changes';
};

/**
 * Get tooltip description for each template element
 */
export const templateElementDescription = (templateElement: TemplateElements): string => {
  switch (templateElement) {
    case TemplateElements.SingleSelect:
      return 'Good for multiple-choice answers. Can only select one answer.';
    case TemplateElements.MultiSelect:
      return 'Good for multiple choice answers. Can select multiple answers.';
    case TemplateElements.ShortAnswer:
      return 'Good for an answer that is a short phrase, sentence, word, or number.';
    case TemplateElements.ShortAnswerList:
      return 'Good for a list of answers that are short phrases, sentences, words, or numbers.';
    case TemplateElements.Paragraph:
      return 'Good for longer, more descriptive answers.';
    case TemplateElements.Date:
      return 'Month/Day/Year';
    case TemplateElements.Time:
      return 'Hour:Minute';
  }
};

/**
 * Compare initial template blocks with current template blocks
 * to determine which blocks need to be updated, created, or deleted
 */
export const compareTemplateBlocks = (
  initialTemplateBlocks: TemplateBlockType[],
  templateBlocks: TemplateBlockType[],
  newTemplateBlockIds: Set<number>
): {
  newBlocks: TemplateBlockType[];
  updatedBlocks: TemplateBlockType[];
  deletedBlocks: TemplateBlockType[];
} => {
  // const newBlocks = templateBlocks.filter((block) => newTemplateBlocks.has(block.id));
  const newBlocks = templateBlocks.filter((block) =>
    initialTemplateBlocks.every((initialBlock) => initialBlock.id !== block.id)
  );

  const updatedBlocks = templateBlocks.filter((block) => {
    const initialBlock = initialTemplateBlocks.find((initialBlock) => initialBlock.id === block.id);
    return (
      initialBlock &&
      JSON.stringify(block) !== JSON.stringify(initialBlock) &&
      !newTemplateBlockIds.has(block.id) // ##PreventNewBlockUpdates
    );
  });

  const deletedBlocks = initialTemplateBlocks.filter(
    (block) =>
      !templateBlocks.some((currentBlock) => currentBlock.id === block.id) &&
      !newTemplateBlockIds.has(block.id) // ##PreventNewBlockUpdates
  );

  return { newBlocks, updatedBlocks, deletedBlocks };
};

/**
 * Check if a block needs a length of list.
 * A template block requires lengthOfList optArg if it is a ShortAnswerList
 * and if it already has optArgs.lengthOfList defined
 */
export const doesBlockNeedLength = (block: TemplateBlockType): boolean => {
  return (
    TemplateElementsWithLength.includes(block.type) &&
    !!block.optArgs &&
    !!block.optArgs.lengthOfList
  );
};

/**
 * Check if a block needs options.
 * A template block requires options optArg if it is a SingleSelect or MultiSelect
 * and if it already has optArgs.options defined
 */
export const doesBlockNeedOptions = (block: TemplateBlockType): boolean => {
  return (
    TemplateElementsWithOptions.includes(block.type) && !!block.optArgs && !!block.optArgs.options
  );
};
