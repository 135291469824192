import { getAccessToken } from '../common/utils/utils';
import { apiBaseUrl } from '../index';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

// Send chat message from user
export const sendMessage = async (
  currentMessage: Message,
  previousMessages: Message[]
): Promise<Message> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/chat/message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      currentMessage: currentMessage,
      previousMessages: previousMessages
    })
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};

// Send chat message for contextual message
export const sendContextualMessage = async (
  currentMessage: Message,
  previousMessages: Message[],
  conversationId: number
): Promise<Message> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/chat/contextual-message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      currentMessage: currentMessage,
      previousMessages: previousMessages,
      conversationId: conversationId
    })
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};
