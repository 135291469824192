import { Disclosure, Transition } from '@headlessui/react';
import { ChatBubbleLeftIcon, ShareIcon } from '@heroicons/react/24/outline';
import { classNames } from '../common/utils/utils';

interface NoteSectionProps {
  id: number;
  question: string;
  description: string;
  children: React.ReactNode;
  isApproved: boolean;
  setisApproved: (isApproved: boolean) => void;
  openShareNotesModal: () => void;
  navigateToChat: () => void;
}

export default function NoteSection({
  id,
  question,
  description,
  children,
  openShareNotesModal,
  navigateToChat
  // isApproved,
  // setisApproved
}: NoteSectionProps) {
  return (
    <div>
      <Disclosure as="div" key={question} className="" defaultOpen={true}>
        {({ open, close }) => (
          <>
            <dt>
              <div
                className={classNames(
                  id === 1 ? 'rounded-t-xl' : '',
                  'flex w-full items-start justify-between text-wrap text-left text-gray-900 mx-auto p-5 font-medium rtl:text-right bg-indigo-50 gap-3 transition-bg duration-300'
                )}
              >
                {/* Section Header */}
                <div className="sm:w-0 sm:flex-1">
                  {/* Section Title */}
                  <h1
                    id="message-heading"
                    className="text-lg font-semibold leading-6 text-gray-700"
                  >
                    {question}
                  </h1>

                  {/* Section Subtitle */}
                  <p className="mt-1 text-sm font-normal text-gray-500">{description}</p>
                  {/* <button
                    type="button"
                    className="flex items-center text-sm font-semibold text-indigo-600 hover:text-indigo-900 mt-2"
                    onClick={openShareNotesModal}
                  >
                    Share
                    <PaperAirplaneIcon className="h-4 w-4 ml-1 inline-block" aria-hidden="true" />
                    <EnvelopeIcon className="h-4 w-4 ml-1 inline-block" aria-hidden="true" />
                  </button> */}
                </div>
                {/* <button
                  type="button"
                  className="flex items-center border border-indigo-500/60 text-indigo-600 hover:text-indigo-900 hover:bg-indigo-500 hover:text-white rounded-md px-2 py-1 text-xs mr-4 font-semibold  mt-4 transition-color duration-300"
                  onClick={openShareNotesModal}
                >
                  Share
                  <EnvelopeIcon className="h-4 w-4 ml-1 inline-block" aria-hidden="true" />
                </button> */}
                <button
                  type="button"
                  className="relative group flex items-center text-indigo-800 text-xs hover:bg-indigo-500 hover:text-white hover:shadow-lg p-1.5 rounded-md font-semibold mt-4 hover:text-indigo-500 transition-color duration-300"
                  onClick={navigateToChat}
                >
                  <ChatBubbleLeftIcon className="h-4 w-4 inline-block" aria-hidden="true" />
                  <span className="absolute bottom-full right-0 mb-[2px] w-56 bg-gray-500 text-white px-2 py-2.5 text-xs leading-4 text-left rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                    Use Notewell AI to expand on these meeting notes: create case plans, draft
                    emails, summarize key points.
                  </span>
                </button>

                <button
                  type="button"
                  className="flex items-center text-indigo-800 text-xs mr-4 hover:bg-indigo-500 hover:text-white hover:shadow-lg p-1.5 rounded-md font-semibold mt-4 hover:text-indigo-500 transition-color duration-300"
                  onClick={openShareNotesModal}
                >
                  {/* <EllipsisVerticalIcon className="h-5 w-5 inline-block" aria-hidden="true" /> */}
                  <ShareIcon className="h-4 w-4 inline-block" aria-hidden="true" />
                  {/* <EnvelopeIcon className="h-[17px] w-[17px] inline-block" aria-hidden="true" /> */}
                </button>

                {/* Section Status Indicator
                {isApproved ? (
                  <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Approved
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-sm font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                    Needs Review
                  </span>
                )} */}

                {/* Section Expand/Collapse Icon */}
                {/* <span className="ml-6 flex h-7 items-center">
                  {open ? (
                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </span> */}
              </div>
            </dt>

            {/* Section Body */}
            <Transition
              enter="transition ease duration-500 transform"
              enterFrom="opacity-0 -translate-y-12"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-12"
            >
              <Disclosure.Panel
                as="dd"
                className="py-4 px-8 lg:py-8 lg:px-12 border border-t-0 border-gray-200"
              >
                {children}

                {/* Approve Section Button */}
                {/* <div className="flex flex-col items-center w-full ">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-2 mb-5 mt-10 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-bg duration-300"
                    onClick={() => {
                      setisApproved(true);
                      // close();
                    }}
                  >
                    Approve Section
                    <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div> */}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
