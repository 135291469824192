import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteAnswerById, updateAnswerById } from '../clients/answerClient';
import {
  getAnswersByMeetingId,
  getCustomQuestionsByMeetingId,
  getMeetingById,
  getMeetingStatus,
  getTranscriptByMeetingId,
  updateMeetingById
} from '../clients/meetingClient';
import { deleteTemplateBlockById } from '../clients/templateBlockClient';
import { getTemplateAndBlocksById } from '../clients/templateClient';
import useNavigation from '../common/navigation';
import {
  AnswerValue,
  ApiError,
  BasicMeetingData,
  ProcessingStatus,
  TemplateBlock,
  TemplateBlockAndAnswer,
  TemplateBlockAnswer
} from '../common/types';
import {
  combineDateAndTime,
  formatDateAs24HourTimeString,
  formatDateAsYMD
} from '../common/utils/dateUtils';
import { zipTemplateBlocksAndAnswers } from '../common/utils/utils';
import BasicMeetingDataSection from '../components/BasicMeetingDataSection';
import CustomQuestion from '../components/CustomQuestion';
import ErrorBanner from '../components/ErrorBanner';
import { LoadingIcon } from '../components/LoadingIcon';
import LoadingState from '../components/LoadingState';
import NoteSection from '../components/NoteSection';
import NoteSubmittedModal from '../components/NoteSubmittedModal';
import ProductTourWrapper from '../components/ProductTourWrapper';
import ShareNotesModal from '../components/ShareNotesModal';
import Sidebar from '../components/Sidebar';
import TemplateBlockStructure from '../components/TemplateBlockStructure';
import TranscriptColumn from '../components/TranscriptColumn';
import { useGlobalContext } from '../context/GlobalProvider';
import { useProductTourContext } from '../context/ProductTourContext';
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning';
import NotFoundPage from './NotFoundPage';

// const _APPROVAL_ALERT_TIMEOUT = 3000;

export default function NotePage() {
  const params = useParams();
  const meetingId = Number(params.meetingId);
  const queryClient = useQueryClient();

  const { goToNotesPage, goToChatPageWithContext } = useNavigation();
  const basicMeetingDataFormRef = useRef<HTMLFormElement>(null);
  const noteFormRef = useRef<HTMLFormElement>(null);
  const { setState } = useGlobalContext();

  const [initialBasicMeetingData, setInitialBasicMeetingData] = useState<BasicMeetingData>(); // Keep track of initial state for comparison
  const [basicMeetingData, setBasicMeetingData] = useState<BasicMeetingData>();
  const [initialTemplateBlocksAndAnswers, setInitialTemplateBlocksAndAnswers] = useState<
    TemplateBlockAndAnswer[]
  >([]); // Keep track of initial state for comparison
  const [templateBlocksAndAnswers, setTemplateBlocksAndAnswers] = useState<
    TemplateBlockAndAnswer[]
  >([]);
  const [initialCustomQuestionsAndAnswers, setInitialCustomQuestionsAndAnswers] = useState<
    TemplateBlockAndAnswer[]
  >([]);
  const [customQuestionsAndAnswers, setCustomQuestionsAndAnswers] = useState<
    TemplateBlockAndAnswer[]
  >([]);
  const [updatedBlockIds, setUpdatedBlockIds] = useState<Set<number>>(new Set()); // Keep track of blocks with updated answers
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  // const [isApprovalAlertShown, setIsApprovalAlertShown] = useState(false);
  const [isTranscriptSourceBarOpen, setIsTranscriptSourceBarOpen] = useState(false); // Must be defined before noteSections
  const [transcriptSourceIndices, setTranscriptSourceIndices] = useState<number[]>([]); // Indices of transcript used to generate answers

  const [isMeetingMetadataUpdated, setIsMeetingMetadataUpdated] = useState(false);
  const [isAnswerUpdatesSuccessful, setIsAnswerUpdatesSuccessful] = useState(false);
  const [isShareNotesModalOpen, setIsShareNotesModalOpen] = useState(false);

  // ============================= PRODUCT TOUR ===============================
  const { setState: setTourState, state: tourState } = useProductTourContext();

  const pauseTour = () => setTourState({ ...tourState, run: false });
  const resumeTour = () => setTourState({ ...tourState, run: true });

  // On mount, if tour is active, trigger the first step for note page
  useEffect(() => {
    if (tourState.tourActive) {
      pauseTour();
      const timer = setTimeout(() => resumeTour(), 600); // ms delay to ensure the tour is active before setting the step index.
      return () => clearTimeout(timer);
    }
  }, []);
  // =========================== END PRODUCT TOUR =============================

  /* Check if there are any form updates */
  const hasChanges = (): boolean => {
    return (
      JSON.stringify(basicMeetingData) !== JSON.stringify(initialBasicMeetingData) ||
      JSON.stringify(templateBlocksAndAnswers) !==
        JSON.stringify(initialTemplateBlocksAndAnswers) ||
      JSON.stringify(customQuestionsAndAnswers) !== JSON.stringify(initialCustomQuestionsAndAnswers)
    );
  };

  // Display confirmation window if user navigates away with unsaved changes
  useUnsavedChangesWarning(hasChanges);

  useEffect(() => {
    // No anwer updates needed if no updated block ids
    if (isMeetingMetadataUpdated && (updatedBlockIds.size == 0 || isAnswerUpdatesSuccessful)) {
      setIsSubmissionModalOpen(true);
      // reset state after opening modal
      setIsMeetingMetadataUpdated(false);
      setIsAnswerUpdatesSuccessful(false);
      // Set initial state to current state
      setInitialBasicMeetingData(basicMeetingData);
      setInitialTemplateBlocksAndAnswers(templateBlocksAndAnswers);
      setInitialCustomQuestionsAndAnswers(customQuestionsAndAnswers);
    }
  }, [isMeetingMetadataUpdated, isAnswerUpdatesSuccessful]);

  /**
   * On component mount (or change in meetingId), we need to
   * 1. get the meeting's basic metadata
   * 2. get the meeting's template blocks (to get the structure of the data)
   * 3. get the meeting's answers
   * 4. render the template blocks zipped together with their corresponding answers
   */
  // Get meeting by id
  const {
    data: meeting,
    error: meetingError,
    isLoading: isMeetingLoading
  } = useQuery({
    queryKey: ['getMeetingById', meetingId],
    queryFn: () => getMeetingById(meetingId)
  });
  // Get template blocks by template id
  const templateId = meeting?.meetingTemplateId;
  const {
    data: templateData,
    error: templateError,
    isLoading: isTemplateLoading
  } = useQuery({
    queryKey: ['getTemplateAndBlocksById', templateId],
    queryFn: () => getTemplateAndBlocksById(templateId!),
    enabled: !!templateId // only run this query once we have templateId from previous query
  });
  // Get custom questions by meeting id
  const {
    data: customQuestions,
    error: customQuestionsError,
    isLoading: isCustomQuestionsLoading
  } = useQuery({
    queryKey: ['getCustomQuestionsByMeetingId', meetingId],
    queryFn: () => getCustomQuestionsByMeetingId(meetingId)
  });
  // Get meeting answers by meeting id
  const {
    data: answers,
    error: meetingAnswerError,
    isLoading: isAnswersLoading
  } = useQuery({
    queryKey: ['getAnswersByMeetingId', meetingId],
    queryFn: () => getAnswersByMeetingId(meetingId),
    enabled: !!templateData
  });
  // Get transcript by meeting id
  const {
    data: transcript,
    error: transcriptError,
    isLoading: isTranscriptLoading
  } = useQuery({
    queryKey: ['getTranscriptByMeetingId', meetingId],
    queryFn: () => getTranscriptByMeetingId(meetingId)
  });
  const potentialErrors = [
    meetingError,
    templateError,
    customQuestionsError,
    meetingAnswerError,
    transcriptError
  ];

  const retriggerQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', templateId] });
    queryClient.invalidateQueries({ queryKey: ['getAnswersByMeetingId', meetingId] });
    queryClient.invalidateQueries({ queryKey: ['getTranscriptByMeetingId', meetingId] });
  };

  // Poll for meeting status every 5 seconds if currently processing
  // 1. Updates meeting status in real-time
  // 2. retriggers queries to load updated template blocks/answers/transcript
  useQuery({
    enabled: meeting !== undefined && meeting?.status === ProcessingStatus.PROCESSING,
    queryKey: ['meetingStatus', meetingId],
    queryFn: async () => {
      const result = await getMeetingStatus(meetingId);
      meeting!.status = result;
      retriggerQueries();
      // console.log('polling status for', meetingId, result);
      return result;
    },
    refetchInterval: 5000 // Poll every 5 seconds
  });

  /**
   * Sort, zip, set necessary state for all data retrieved by API requests
   * - restrict meeting data into basic meeting data format
   * - zip template blocks and answers together
   * - sort transcript lines by position
   */
  useEffect(() => {
    // Restrict meeting into basic meeting data format
    if (meeting) {
      const basicMeetingData: BasicMeetingData = {
        worker: meeting.worker!,
        client: meeting.client,
        date: formatDateAsYMD(meeting.dateTime),
        time: formatDateAs24HourTimeString(meeting.dateTime),
        location: meeting.location,
        template: templateData?.template
      };
      setInitialBasicMeetingData(basicMeetingData);
      setBasicMeetingData(basicMeetingData);
    }
  }, [meeting, templateData]);

  useEffect(() => {
    // Sort template blocks by position
    if (templateData) {
      templateData.blocks.sort((a: TemplateBlock, b: TemplateBlock) => a.position - b.position); // Sort by position
    }
    // Zip template blocks and answers together
    if (templateData && answers) {
      const blocksAndAnswers: TemplateBlockAndAnswer[] = zipTemplateBlocksAndAnswers(
        templateData.blocks,
        answers
      );
      setTemplateBlocksAndAnswers(blocksAndAnswers);
      setInitialTemplateBlocksAndAnswers(blocksAndAnswers);
    }
  }, [templateData, answers]);

  useEffect(() => {
    if (customQuestions) {
      customQuestions.sort((a: TemplateBlock, b: TemplateBlock) => a.position - b.position); // Sort by position
    }
    // Add custom questions to the end of the list
    if (customQuestions && answers) {
      const zippedQuestionsAndAnswers: TemplateBlockAndAnswer[] = zipTemplateBlocksAndAnswers(
        customQuestions,
        answers
      );
      setCustomQuestionsAndAnswers(zippedQuestionsAndAnswers);
      setInitialCustomQuestionsAndAnswers(zippedQuestionsAndAnswers);
    }
  }, [customQuestions, answers]);

  useEffect(() => {
    // Sort transcript lines by position
    if (transcript) {
      transcript.sort((a, b) => a.position - b.position); // Sort by position
    }
  }, [transcript]);

  // ============================== Mutations ==================================
  // Update meeting mutation that is triggered on button click
  const updateMeetingMutation = useMutation({
    mutationFn: ({
      location,
      dateTime,
      clientId
    }: {
      location?: string;
      dateTime?: string;
      clientId?: number;
    }) => {
      return updateMeetingById(meetingId, location, dateTime, clientId);
    }
  });

  // Update template block answers mutation that is triggered on button click
  const updateAnswerMutation = useMutation({
    mutationFn: (answer: TemplateBlockAnswer) => {
      // Update answers
      return updateAnswerById(answer);
    }
  });

  const deleteQuestionMutation = useMutation({
    mutationFn: (questionId: number) => {
      return deleteTemplateBlockById(questionId);
    },
    onMutate: (questionId: number) => {
      const deletedQuestion = customQuestionsAndAnswers.find(
        (questionAndAnswer) => questionAndAnswer.block.id === questionId
      );
      // Optimistically update list of template blocks and answers
      setCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) =>
        prevQuestionsAndAnswers.filter(
          (questionAndAnswer) => questionAndAnswer.block.id !== questionId
        )
      );
      setInitialCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) =>
        prevQuestionsAndAnswers.filter(
          (questionAndAnswer) => questionAndAnswer.block.id !== questionId
        )
      );
      return deletedQuestion;
    },
    onSuccess: () => {
      // Show success toast
      setState((prev) => ({ ...prev, deleteCustomQuestionSuccess: true }));
    },
    onError: (_1, _2, deletedQuestion: TemplateBlockAndAnswer | undefined) => {
      setState((prev) => ({ ...prev, deleteCustomQuestionError: true }));
      //  Roll back the optimistic update
      if (deletedQuestion) {
        setCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) =>
          prevQuestionsAndAnswers.concat(deletedQuestion)
        );
        setInitialCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) =>
          prevQuestionsAndAnswers.concat(deletedQuestion)
        );
      }
    }
  });

  const deleteAnswerMutation = useMutation({
    mutationFn: ({ answerId, questionId }: { answerId: number; questionId: number }) => {
      return deleteAnswerById(answerId, questionId);
    }
  });
  // ===========================================================================

  /**
   * Opens transcript source bar and populates it with source indices.
   * Triggered when user clicks source request button
   */
  const displaySourceIndices = async (sourceIndices?: number[]) => {
    setIsTranscriptSourceBarOpen(true);
    if (sourceIndices) setTranscriptSourceIndices(sourceIndices);
  };

  /**
   * Handle the value change of a template block's answer (ex:
   * a date value is updated)
   */
  const handleBlockAnswerUpdate = (blockId: number, newAnswerValue: AnswerValue): void => {
    const oldAnswer = templateBlocksAndAnswers.find(
      (blockAndAnswer) => blockAndAnswer.block.id === blockId
    )?.answer;
    // Validate that the answer exists (it won't if the block has an empty question)
    if (!oldAnswer) return;

    const newAnswer = { ...oldAnswer, answer: newAnswerValue };
    setTemplateBlocksAndAnswers((prevBlocksAndAnswers: TemplateBlockAndAnswer[]) =>
      prevBlocksAndAnswers.map((blockWithAnswer: TemplateBlockAndAnswer) =>
        blockWithAnswer.block.id === blockId
          ? {
              ...blockWithAnswer,
              answer: newAnswer
            }
          : blockWithAnswer
      )
    );

    // Keep track of blocks with updated answers
    setUpdatedBlockIds(
      (prevUpdatedBlockIds: Set<number>) => new Set(prevUpdatedBlockIds.add(blockId))
    );
  };

  const handleCustomQuestionAnswerUpdate = (blockId: number, newAnswerValue: AnswerValue): void => {
    const oldAnswer = customQuestionsAndAnswers.find(
      (questionAndAnswer) => questionAndAnswer.block.id === blockId
    )?.answer;
    // Validate that the answer exists (it won't if the block has an empty question)
    if (!oldAnswer) return;

    const newAnswer = { ...oldAnswer, answer: newAnswerValue };

    setCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) =>
      prevQuestionsAndAnswers.map((questionAndAnswer: TemplateBlockAndAnswer) =>
        questionAndAnswer.block.id === blockId
          ? {
              block: questionAndAnswer.block,
              answer: newAnswer
            }
          : questionAndAnswer
      )
    );

    // Keep track of blocks with updated answers
    setUpdatedBlockIds(
      (prevUpdatedBlockIds: Set<number>) => new Set(prevUpdatedBlockIds.add(blockId))
    );
  };

  if (
    isAnswersLoading ||
    isMeetingLoading ||
    isTemplateLoading ||
    isTranscriptLoading ||
    isCustomQuestionsLoading
  )
    return (
      <>
        <Sidebar currentPageName={'Meeting Notes'} />
        <main className="lg:pl-72 flex items-center">
          <LoadingState />
        </main>
      </>
    );

  if (meetingError instanceof ApiError && meetingError.status === 404) {
    return <NotFoundPage />;
  }

  /**
   * Handle the submission of the notes by saving updates to meeting metadata
   * and notes content. Triggered by "Submit Notes" button
   */
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (
      basicMeetingDataFormRef.current?.reportValidity() &&
      noteFormRef.current?.reportValidity()
    ) {
      if (!basicMeetingData) {
        alert('Something went wrong. Please refresh the page and try again.');
        return;
      }

      // Update meeting metadata
      updateMeetingMutation.mutate(
        {
          location: basicMeetingData.location,
          dateTime: combineDateAndTime(basicMeetingData.date, basicMeetingData.time).toISOString(),
          clientId: basicMeetingData.client.id
        },
        {
          onSuccess: () => setIsMeetingMetadataUpdated(true)
        }
      );

      // Update answers
      templateBlocksAndAnswers.forEach((blockAndAnswer) => {
        // Do not attempt to update if the block has no answer
        if (updatedBlockIds.has(blockAndAnswer.block.id) && blockAndAnswer.answer) {
          updateAnswerMutation.mutate(blockAndAnswer.answer, {
            onSuccess: () => setIsAnswerUpdatesSuccessful(true)
          });
        }
      });
      customQuestionsAndAnswers.forEach((blockAndAnswer) => {
        if (updatedBlockIds.has(blockAndAnswer.block.id) && blockAndAnswer.answer) {
          updateAnswerMutation.mutate(blockAndAnswer.answer, {
            onSuccess: () => setIsAnswerUpdatesSuccessful(true)
          });
        }
      });
    }
  };

  const openShareNotesModal = () => {
    setIsShareNotesModalOpen(true);
  };

  // Adds a new custom question to the end of the list
  const updateCustomQuestionsAndAnswers = (newQuestionAndAnswer: TemplateBlockAndAnswer) => {
    setCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) => [
      ...prevQuestionsAndAnswers,
      newQuestionAndAnswer
    ]);
    setInitialCustomQuestionsAndAnswers((prevQuestionsAndAnswers: TemplateBlockAndAnswer[]) => [
      ...prevQuestionsAndAnswers,
      newQuestionAndAnswer
    ]);
  };

  const handleTrashCustomQuestion = (questionId: number, answerId?: number) => {
    // Display confirmation window
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this question? You cannot undo this action.'
    );
    if (!isConfirmed) return;

    // Chain deletion of answer and question, since question cannot be deleted
    // if answer exists
    if (answerId) {
      deleteAnswerMutation
        .mutateAsync({ answerId, questionId })
        .then(() => deleteQuestionMutation.mutate(questionId));
    } else {
      deleteQuestionMutation.mutate(questionId);
    }
  };

  /**
   * Handles the action button being pressed. This redirects to the user
   * to the ChatPage and passes relevant information for the action being taken.
   * For now, no information is passed other than the conversation id since we
   * are taking a generic action. Also the fact that it is a generic action is
   * communicated.
   */
  const handleActionButtonClick = () => {
    goToChatPageWithContext(meetingId);
  };

  // Determine the next position index for all template blocks and custom questions
  const getLargestQuestionPosition = () => {
    const customQuestionPositions = customQuestionsAndAnswers.map(
      (questionAndAnswer) => questionAndAnswer.block.position
    );
    const templateBlockPositions = templateBlocksAndAnswers.map(
      (blockAndAnswer) => blockAndAnswer.block.position
    );
    const largestPosition = Math.max(...customQuestionPositions, ...templateBlockPositions, -1); // -1 for empty list case (max of two empty lists is -Infinity)
    return largestPosition;
  };

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Meeting Notes'} />
      {/* <NoteSectionApprovedAlert open={isApprovalAlertShown} /> */}
      <main className="relative lg:pl-72 print:w-full print:pl-0 relative z-10">
        <div className="xl:pr-96 print:pr-0">
          <div
            id="note-body"
            className="relative px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-12 bg-white"
          >
            {/* If error has been thrown during one of the API requests */}
            {potentialErrors.map((error) =>
              error && error instanceof Error ? <ErrorBanner message={error.message} /> : null
            )}
            {
              <div>
                {/* Heading */}
                <div className="border-b border-gray-200 pt-4 pb-6">
                  <h3 className="text-3xl font-bold text-gray-800">
                    {/* {templateData?.template.title}{' '} */}
                    {/* <span className="font-medium"> with {basicMeetingData?.client.name}</span> */}
                    {meeting?.title}
                  </h3>
                </div>
                {/* <div className="absolute right-12">
                  <button
                    type="button"
                    className="mt-6 items-center hover:bg-indigo-400 p-2 rounded-md text-xs font-semibold transition-color duration-300"
                    onClick={openShareNotesModal}
                  >
                    Share
             
                    <ShareIcon
                      className="h-3.5 w-3.5 ml-1 -mt-0.5 inline-block"
                      aria-hidden="true"
                    />
                    <EllipsisVerticalIcon
                      className="h-5 w-5 inline-block text-gray-700"
                      aria-hidden="true"
                    />
                  </button>
                </div> */}

                {/* Basic Meeting Data*/}
                <form
                  ref={basicMeetingDataFormRef}
                  id="basicMeetingDataForm"
                  onSubmit={(e) => e.preventDefault()}
                >
                  {basicMeetingData && (
                    <BasicMeetingDataSection
                      basicMeetingData={basicMeetingData}
                      setBasicMeetingData={setBasicMeetingData}
                    />
                  )}
                </form>

                {meeting?.status === ProcessingStatus.COMPLETED && (
                  <div className="mt-6 mx-auto max-w-7xl lg:px-6">
                    {/* Header */}
                    {/* <h3 className="text-xl font-semibold text-gray-900">General Meeting Notes</h3>
                      <h5 className="text-sm font-normal text-gray-500 mb-8">
                        Notes generated using selected template and meeting recording
                      </h5> */}
                    {/* Template Blocks and Answers */}
                    {/* <dl className="space-y-5 px-4"> */}
                    <NoteSection
                      id={1}
                      question={'Meeting Notes'}
                      description={
                        'Notes generated using your selected template and meeting recording'
                      }
                      isApproved={false}
                      setisApproved={(isApproved) => {
                        // _updateisApproved(isApproved, section.id);
                        // approveSection();
                      }}
                      openShareNotesModal={openShareNotesModal}
                      navigateToChat={handleActionButtonClick}
                    >
                      <form ref={noteFormRef} id="noteForm" onSubmit={(e) => e.preventDefault()}>
                        <ol className="list-decimal list-outside">
                          {templateBlocksAndAnswers?.map((blockAndAnswer, index) => {
                            return (
                              <TemplateBlockStructure
                                id={index === 0 ? 'first-block' : undefined}
                                key={blockAndAnswer.block.id}
                                meetingId={meetingId}
                                block={blockAndAnswer.block}
                                answer={blockAndAnswer.answer}
                                handleBlockAnswerUpdate={handleBlockAnswerUpdate}
                                displaySourceIndices={displaySourceIndices}
                                isCustomQuestion={false}
                                handleTrash={() => {}}
                              />
                            );
                          })}
                          {customQuestionsAndAnswers?.map((questionAndAnswer) => {
                            return (
                              <TemplateBlockStructure
                                key={questionAndAnswer.block.id}
                                meetingId={meetingId}
                                block={questionAndAnswer.block}
                                answer={questionAndAnswer.answer}
                                handleBlockAnswerUpdate={handleCustomQuestionAnswerUpdate}
                                displaySourceIndices={displaySourceIndices}
                                isCustomQuestion={true}
                                handleTrash={handleTrashCustomQuestion}
                              />
                            );
                          })}
                        </ol>
                      </form>
                      <CustomQuestion
                        className="mt-12"
                        meetingId={meetingId}
                        nextQuestionIndex={getLargestQuestionPosition() + 1}
                        updateCustomQuestionsAndAnswers={updateCustomQuestionsAndAnswers}
                      />
                    </NoteSection>
                    {/* </dl> */}
                    {/* Submit Note Button */}
                    <div className="flex flex-col items-center w-full">
                      <button
                        type="button"
                        onClick={(e) => handleSubmit(e)}
                        className="inline-flex items-center gap-x-2 mt-10 rounded-md bg-indigo-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                        disabled={updateAnswerMutation.isPending || !hasChanges()}
                      >
                        {updateAnswerMutation.isPending ? (
                          <>
                            Saving...
                            <LoadingIcon className="ml-1" aria-hidden="true" />
                          </>
                        ) : (
                          <>
                            Save Changes
                            <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                          </>
                        )}
                      </button>
                      {updateMeetingMutation.error && (
                        <ErrorBanner message={updateMeetingMutation.error.message} />
                      )}
                      {updateAnswerMutation.error && (
                        <ErrorBanner message={updateAnswerMutation.error.message} />
                      )}
                    </div>
                    <NoteSubmittedModal
                      open={isSubmissionModalOpen}
                      setOpen={setIsSubmissionModalOpen}
                    />
                  </div>
                )}
                {meeting?.status === ProcessingStatus.NOT_STARTED && (
                  // If meeting is not processed
                  <div className="rounded-md bg-indigo-50 border border-indigo-100 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-indigo-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-indigo-700">
                          You haven't uploaded a recording for this meeting yet. You can do this in
                          the{' '}
                          <button
                            onClick={goToNotesPage}
                            className="underline underline-offset-4 hover:text-indigo-400 transition-color duration-300"
                          >
                            <p>Meeting Notes</p>
                          </button>{' '}
                          page.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {meeting?.status === ProcessingStatus.PROCESSING && (
                  <LoadingState text={'Processing meeting....'} />
                )}
                {meeting?.status === ProcessingStatus.ERROR && (
                  <div className="rounded-md bg-red-50 border border-red-200 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-red-700">
                          There was an error processing the meeting. Please try again later.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <ShareNotesModal
                  meetingId={meetingId}
                  isOpen={isShareNotesModalOpen}
                  setIsOpen={setIsShareNotesModalOpen}
                />
              </div>
            }
          </div>
        </div>
        <TranscriptColumn
          isTranscriptSourceBarOpen={isTranscriptSourceBarOpen}
          setIsTranscriptSourceBarOpen={setIsTranscriptSourceBarOpen}
          transcriptSourceIndices={transcriptSourceIndices}
          transcriptChunks={transcript ? transcript : []}
        />
      </main>
    </ProductTourWrapper>
  );
}
function goToChatPageWithContext(conversationId: number) {
  throw new Error('Function not implemented.');
}
