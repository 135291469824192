import { useNavigate } from 'react-router-dom';

const useNavigation = () => {
  const navigate = useNavigate();

  return {
    goToHomePage: () => navigate('/'),
    goToClientsPage: () => navigate('/clients'),
    goToClientPage: (clientId: number) => navigate(`/clients/${clientId}`),
    goToChatPage: () => navigate('/chat'),
    goToChatPageWithContext: (conversationId: number) =>
      navigate(`/chat/${conversationId}`, { state: { isGenericAction: true } }),
    goToNotesPage: () => navigate('/meeting-notes'),
    goToNotePage: (meetingId: number) => navigate('/meeting-notes/' + meetingId),
    goToRecordPage: () => navigate('/record'),
    goToRecordWithMeetingPage: (meetingId: number) =>
      navigate(`/meeting-notes/${meetingId}/record`),
    goToCreateTemplatePage: () => navigate('/meeting-templates/new'),
    goToTemplatePage: (templateId: number, isNewTemplate: boolean) =>
      navigate(`/meeting-templates/${templateId}`, { state: { isNewTemplate } }),
    goToTemplatesPage: () => navigate('/meeting-templates')
  };
};

export default useNavigation;
